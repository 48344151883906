import React from "react"

import RichText from "../RichText"

import { NutritionFieldsModel } from "../../models/pages"
import NutritionTable from "../NutritionTable/NutritionTable"
import useDictionary from "../../hooks/useDictionary"

interface TabProps {
  nutrition?: NutritionFieldsModel
  serving_size?: string
  quantity_per_serve?: string
  servings_per_pack?: string
  nutrition_footer?: any
  pageContext: any
}

export const defaultProps = {}

export const NutritionTab: React.FC<TabProps> = props => {
  if (!props.nutrition) return null

  return (
    <div className="tab__content">
      <div className="tab__box">
        <p>
          <span style={{ marginRight: props.servings_per_pack ? 20 : 0 }}>
            {useDictionary("Serving size", props.pageContext.locale)}:{" "}
            {props.serving_size}
            {props.quantity_per_serve && <> ({props.quantity_per_serve})</>}
          </span>
          {props.servings_per_pack && (
            <>
              {useDictionary("Servings per pack", props.pageContext.locale)}:{" "}
              {props.servings_per_pack}
            </>
          )}
        </p>

        <NutritionTable
          nutrition={props.nutrition}
          pageContext={props.pageContext}
        />
        {JSON.stringify(props.nutrition).includes("RDI") && (
          <>
            {useDictionary(
              "* Percentage of Recommended Dietary Intake (RDI)",
              props.pageContext.locale
            )}
          </>
        )}
        <RichText
          content={props.nutrition_footer}
          pageContext={props.pageContext}
        />
      </div>
    </div>
  )
}

NutritionTab.defaultProps = defaultProps

export default NutritionTab
