import React from "react"

import RichText from "../RichText"
import Image from "../Image/Image"

import { ProductFlavourModel } from "../../models/pages"
import { isEmptyRichText } from "../../utils"
import useDictionary from "../../hooks/useDictionary"

interface Props extends ProductFlavourModel {}

export const defaultProps = {}

export const BenefitsTab: React.FC<Props> = props => {
  return (
    <div className="tab__content">
      {!isEmptyRichText(props.benefits) && (
        <div className="tab__box">
          <RichText content={props.benefits} pageContext={props.pageContext} />
        </div>
      )}

      {props.health_rating && (
        <div
          className="text-center"
          style={{ marginTop: isEmptyRichText(props.benefits) ? 0 : 40 }}
        >
          <h3>{useDictionary("Health Star Rating", props.pageContext.locale)}</h3>
          <div style={{ marginBottom: 30 }}>
            <Image image={props.health_rating} />
          </div>
          <p dangerouslySetInnerHTML={{__html: useDictionary("Health Star Rating description", props.pageContext.locale)}}>
          </p>
        </div>
      )}
      {props.hsr_text && (
        <div
          className="text-center"
          style={{ marginTop: isEmptyRichText(props.benefits) ? 0 : 40 }}
        >
          <RichText
            content={props.hsr_text}
            cssClass="content"
            pageContext={props.pageContext}
          />
        </div>
      )}
    </div>
  )
}

BenefitsTab.defaultProps = defaultProps

export default BenefitsTab
