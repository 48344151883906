import React from "react"

import { HeroSlide } from "../../../models/common"

interface Props extends HeroSlide {}

const defaultProps = {}

export const HeroStaticImageSlide: React.FC<Props> = props => {
  return (
    <img
      src={props?.skuImage}
      className="slide__image"
      style={{ backgroundSize: "contain", margin: "0 auto" }}
    />
  )
}

HeroStaticImageSlide.defaultProps = defaultProps

export default HeroStaticImageSlide
