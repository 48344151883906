import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"

interface Props {
  tabs: {
    heading: string
    content: React.ReactElement
  }[]
  cssClass?: string
}

const defaultProps = {}

export const Tabs: React.FC<Props> = props => {
  // state
  const [active, setActive] = useState(0)

  // refs
  const sliderRef = useRef<Slider>(null)

  // events
  const changeSlide = (index: number) => {
    sliderRef.current?.slickGoTo(index)
    setActive(index)
  }

  useEffect(() => {
    if (sliderRef.current) {
      setTimeout(() => {
        sliderRef.current?.slickGoTo(active, true)
      }, 200)
    }
  }, [sliderRef.current])

  if (!props.tabs) return null

  const sliderOptions = {
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: active,
    fade: true,
    swipe: false,
    adaptiveHeight: true,
  }

  return (
    <div className="tabs">
      <div className="tabs__triggers">
        {props.tabs.map((tab, index) => (
          <button
            key={`tab_${index}`}
            className={`tabs__trigger ${active === index ? "active" : ""}`}
            onClick={() => changeSlide(index)}
          >
            {tab.heading}
          </button>
        ))}
      </div>
      <div className="tabs__content">
        <Slider {...sliderOptions} ref={sliderRef}>
          {props.tabs.map((tab, index) => (
            <div key={`tabContent_${index}`}>{tab.content}</div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

Tabs.defaultProps = defaultProps

export default Tabs
