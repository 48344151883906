import React from "react"

import HeroSlider from "../Slider/HeroSlider"

import { HeroSlide } from "../../models/common"

interface Props {
  slides: HeroSlide[]
}

const defaultProps = {}

export const ProductHero: React.FC<Props> = props => {
  if (!props.slides) return null
  return (
    <section className="hero">
      <div className="grid-container">
        <HeroSlider slides={props.slides} imageStyle="contain" />
      </div>
    </section>
  )
}

ProductHero.defaultProps = defaultProps

export default ProductHero
