import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"

import HeroImageSlide from "./Slides/HeroImageSlide"
import HeroVideoSlide from "./Slides/HeroVideoSlide"

import { HeroSlide } from "../../models/common"
import HeroStaticImageSlide from "./Slides/HeroStaticImageSlide"

interface Props {
  slides: HeroSlide[]
  imageStyle?: "cover" | "contain"
}

const defaultProps = {}

export const HeroSlider: React.FC<Props> = props => {
  if (!props.slides) return null

  // state
  const [slider, setSlider] = useState<Slider>()
  const [sliderHelper, setSliderHelper] = useState<Slider>()

  // refs
  const sliderRef = useRef<Slider>(null)
  const sliderHelperRef = useRef<Slider>(null)

  // effects
  useEffect(() => {
    setSlider(sliderRef.current || undefined)
    setSliderHelper(sliderHelperRef.current || undefined)
  }, [])

  const sliderSettings = {
    arrows: true,
    infinite: true,
    centerMode: false,
    fade: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 639,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "7.5%",
          fade: false,
          swipe: true,
        },
      },
    ],
  }

  return (
    <div className="grid-x grid-margin-x align-center">
      <div className="cell">
        {props.slides.length === 1 && (
          <div className="heroSlider">
            {props.slides[0].skuImage ? (
              <HeroStaticImageSlide {...props.slides[0]} />
            ) : (
              <HeroImageSlide {...props.slides[0]} />
            )}
          </div>
        )}

        {props.slides.length > 1 && (
          <Slider
            {...sliderSettings}
            className="heroSlider"
            ref={sliderRef}
            asNavFor={sliderHelper}
          >
            {props.slides.map((slide, i) => {
              if (slide.video_url)
                return <HeroVideoSlide key={`slide_${i}`} {...slide} />
              else if (slide?.image)
                return <HeroImageSlide key={`slide_${i}`} {...slide} />
              else if (slide?.skuImage)
                return <HeroStaticImageSlide key={`slide_${i}`} {...slide} />
              else return null
            })}
          </Slider>
        )}
      </div>
    </div>
  )
}

HeroSlider.defaultProps = defaultProps

export default HeroSlider
