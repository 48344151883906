import React from "react"

import RichText from "../RichText"

import { ProductFlavourModel } from "../../models/pages"

interface Props extends ProductFlavourModel {}

export const defaultProps = {}

export const IngredientsTab: React.FC<Props> = props => {
  if (!props.ingredients) return null

  return (
    <div className="tab__content">
      <div className="tab__box">
        <RichText content={props.ingredients} pageContext={props.pageContext} />
      </div>
    </div>
  )
}

IngredientsTab.defaultProps = defaultProps

export default IngredientsTab
