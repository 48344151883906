import React from "react"

import RichText from "../RichText"

import { ProductFlavourModel } from "../../models/pages"

interface Props extends ProductFlavourModel {}

export const defaultProps = {}

export const FAQTab: React.FC<Props> = props => {
  // console.log(props)
  if (!props.faqs) return null

  return (
    <div className="tab__content">
      <div
        className="tab__box"
        dangerouslySetInnerHTML={{ __html: props.faqs }}
      ></div>
    </div>
  )
}

FAQTab.defaultProps = defaultProps

export default FAQTab
