import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import ConditionalWrapper from "../ConditionalWrapper"

import { getAltText } from "../../utils"
import { CmsImage } from "../../models/common"

interface Props {
  store_logo?: CmsImage
  product_link?: string
}

const defaultProps = {}

export const ShopCard: React.FC<Props> = props => {
  if (!props.store_logo) return null

  return (
    <div className="card shopCard">
      <div
        className="grid-y card__inner"
        style={{ textAlign: "center", padding: 10 }}
      >
        <ConditionalWrapper
          condition={props.product_link !== ""}
          wrapper={children => (
            <a
              href={props.product_link}
              className="card__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          )}
        >
          {props.store_logo && (
            <GatsbyImage
              image={props.store_logo.gatsbyImageData}
              alt={getAltText(props.store_logo) || ""}
              className="card__image"
              objectFit="contain"
              objectPosition="center"
              style={{ height: 130 }}
            />
          )}
        </ConditionalWrapper>
      </div>
    </div>
  )
}

ShopCard.defaultProps = defaultProps

export default ShopCard
